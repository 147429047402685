<template>
  <div class="row parameter">
    <div class="col-12">
      <h6>{{ parametre.libelle }}</h6>
    </div>
    <div class="col-6 localisation">
      <div class="form-group">
        <label :for="'localisation-'+index">Localisation lesion</label>
        <input
          class="form-control"
          :name="'localisation-'+index"
          :id="'localisation-'+index"
          v-model="localisation"
        >
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label :for="'caracterisation-'+index">Caracterisation</label>
        <input
          class="form-control"
          :name="'caracterisation-'+index"
          :id="'caracterisation-'+index"
          v-model="caracterisation"
        >
      </div>   
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ANALYSE_INTERPRETATION } from '../../../constants/app';
export default {
    props: {
        parametre: {type: Object, required: true},
        typeFiche: {type: Object, required: true},
        animal: {type: String, required: true},
        index: {type: Number, required: true}
    },
    data(){
        return {
            localisation: null,
            caracterisation: null
        }
    },
    mounted(){
        this.updateValeur()
    },
    watch: {
        monit: {
            handler(){
                this.addResultat(this.monit)
            },
            deep: true
        },
        reset(){
            if(this.reset) this.valeur = null
        },
        resultats: {
            handler(){
                this.updateValeur()
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            addResultat: 'analyse/ADD_RESULTAT',
        }),
        updateValeur(){
            if(this.resultats.some(item => item.parametre === this.parametre.uid)){
                let r = this.resultats.find(item => item.parametre === this.parametre.uid)
                if(this.localisation !== r.localisation) this.localisation = r.localisation
                if(this.caracterisation !== r.caracterisation) this.caracterisation = r.caracterisation
            }
        }
    },
    computed: {
        ...mapGetters({
            reset: 'analyse/reset',
            animaux: 'identification/animaux',
            espece: 'identification/especes',
            resultats: 'analyse/resultats',
        }),
        
        isInterpretationFiche(){
            if(this.typeFiche !== null) return this.typeFiche.code === ANALYSE_INTERPRETATION
            return false
        },
        monit(){
            return {order: this.index, localisation: this.localisation, caracterisation: this.caracterisation, parametre: this.parametre.uid}
        },
        animalObject(){
            return this.animaux.find(item => item.uid === this.animal)
        }
    }

}
</script>

<style lang="scss" scoped>
.parameter{
    border-bottom: 1px solid rgba(0, 0, 0, 0.17); 
    margin-bottom: 10px;
    .localisation{
        border-right: 1px solid rgba(0, 0, 0, 0.17);
    }
}
</style>