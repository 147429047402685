<template>
  <div>
    <div class="row">
      <div class="col-12 text-right">
        <a
          class="btn btn-success"
          href="javascript:void(0)"
          @click.prevent="resetForm"
        > <i /> Reset valeurs</a>
        <a
          class="btn btn-primary"
          href="javascript:void(0)"
          @click.prevent=" showAnimal = !showAnimal"
        > 
          Voir détail
        </a>
      </div>
    </div>
    <div class="row">
      <div :class="showAnimal? 'col-7':'col-12'">
        <form @submit.prevent="adding">
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <div v-if="isReferenceValeurFiche || isResultatDirectFiche">
            <input-resultat-analyse
              v-for="(p,i) in filtredParametres"
              :key="i"
              :index="i"
              :parametre="p"
              :type-fiche="type"
              :animal="animal"
            />
          </div>
          <div v-if="isInterpretationFiche">
            <input-interpretation-analyse
              v-for="(p,i) in filtredParametres"
              :key="i"
              :index="i"
              :parametre="p"
              :type-fiche="type"
              :animal="animal"
            />
          </div>
          
          <div
            class="form-group"
            v-if="isReferenceValeurFiche || isInterpretationFiche"
          >
            <label for="commentaire">{{ isInterpretationFiche? 'Synthèse de la description ':'Commentaire' }}</label>
            <textarea
              rows="5"
              class="form-control"
              name="commentaire"
              id="commentaire"
              v-model="commentaire"
            />
          </div>
          <div
            class="form-group"
            v-if="isInterpretationFiche"
          >
            <label for="conclusion">Interpretation</label>
            <textarea
              rows="3"
              class="form-control"
              name="conclusion"
              id="conclusion"
              v-model="conclusion"
            />
          </div>
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
      <div
        class="col-5"
        v-if="showAnimal"
      >
        <div class="row">
          <div class="col-12">
            <h4> Resultat</h4>
            <p
              v-for="(r,i) in resultats"
              :key="i"
            >
              {{ r.order }} - {{ r.parametre }} - {{ r.valeur }}
            </p>
          </div>
        </div>
            
        <div
          class="row"
          v-if="showAnimal"
        >
          <div class="col-12">
            <h4> Animal</h4>
            <animal :animal-id="animal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import inputResultatAnalyse from './inputResultatAnalyse.vue';
import Animal from '../../identification/animal/animalVerticalDetail.vue'
import {ANALYSE_INTERPRETATION, ANALYSE_REFERENCE_VALEUR, ANALYSE_RESULTAT_DIRECT} from '../../../constants/app'
import { ADD_RESULTAT_ANALYSE_FICHE } from '../../../graphql/ficheAnalyse';
import InputInterpretationAnalyse from './inputInterpretationAnalyse.vue';
export default {
    components: {inputResultatAnalyse, Animal, InputInterpretationAnalyse},
    props: {
        fiche: {type: String, required: true},
        analyse: {type: String, required: true}
    },

    data(){
        return {
            showAnimal: false,
            commentaire: null,
            conclusion: null,
            has_error: false,
            error_msg: null
        }
    },
    methods: {
        ...mapMutations({
            initResultat: 'analyse/INIT_RESULTAT',
            setReset: 'analyse/SET_RESET',
            done: 'DONE'
        }),
        resetForm(){
            this.has_error = false
            this.error_msg = null
            this.setReset(true)
            setTimeout(() => {
                this.setReset(false)
                this.initResultat()
            }, 1000)
        },
        adding(){
            let data = {
                analyse: this.analyse,
                data: JSON.stringify(this.resultats),
                commentaire: this.commentaire,
                info: this.conclusion
            }
            this.$apollo.mutate({
                mutation: ADD_RESULTAT_ANALYSE_FICHE,
                variables: {
                    "resultat": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Resultat analyse fiche ${this.libelle} add successfully`)
                }
            }).then(() => {
                this.resetForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            fiches: 'sanitaire/ficheAnalyses',
            typeFiches: 'sanitaire/typeFicheAnalyses',
            parametres: 'sanitaire/ficheParametres',
            analyses: 'sanitaire/analyses',
            resultats: 'analyse/resultats'
        }),
        type(){
            let f = this.fiches.find(item => item.uid === this.fiche)
            if(f) {
                let t = this.typeFiches.find(item => item.uid === f.categorieAnalyse)
                if(t) return t
            }
            return null
        },
        isReferenceValeurFiche(){
            if(this.type !== null) return this.type.code === ANALYSE_REFERENCE_VALEUR
            return false
        },
        isResultatDirectFiche(){
            if(this.type !== null) return this.type.code === ANALYSE_RESULTAT_DIRECT
            return false
        },
        isInterpretationFiche(){
            if(this.type !== null) return this.type.code === ANALYSE_INTERPRETATION
            return false
        },
        analyseObject(){
            let a = this.analyses.find(item => item.uid === this.analyse)
            if(a) return a
            return null
        },
        animal(){
            return (this.analyseObject !== null)? this.analyseObject.animal : null
        },
        filtredParametres(){
            return [...this.parametres].filter(item => item.fiche.split(',').includes(this.fiche))
        }
    }

}
</script>

<style>

</style>