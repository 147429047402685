<template>
  <base-component
    :animal-uid="animal"
    :date="date"
    v-if="visible"
  >
    <template slot="title">
      <h4 style=" text-align: center; text-decoration: underline; text-transform: uppercase;"> 
        Resultat d'analyse: {{ fiche|libelle }}
      </h4>
    </template>
    <template slot="content">
      <div class="row">
        <div class="col-12">
          <table
            class="table table-hover table-bordered table-striped"
            v-if="isSectionTab"
          >
            <thead>
              <tr>
                <th>Paramètre</th>
                <th>Valeur</th>
                <th>
                  Reférence {{ espece|libelle }} [{{ espece.code }}]
                </th>
              </tr>
            </thead>
            <tbody
              v-for="(s,index) in filtredSections"
              :key="index"
            >
              <tr>
                <td
                  colspan="100%"
                  style="background-color:rgba(0,0,0,0.15); color: #5c5c5c; text-align: center; font-weight: bold; font-size: 14px;"
                >
                  {{ s|libelle }}
                </td>
              </tr>
              <tr
                v-for="(p, i) in filtredParametres(s)"
                :key="i"
              >
                <td>{{ p|libelle }}</td>
                <td class="valeur">
                  <span>{{ valeur(p) }}</span> <small
                    style="color: #b50404;"
                    v-html="NormalValeur(p)"
                  />
                </td>
                <td>
                  {{ valeurRef(p, espece) }}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            class="table table-hover table-bordered table-striped"
            v-if="!isSectionTab"
          >
            <thead>
              <tr>
                <th>Paramètre</th>
                <th>Valeur</th>
                <th>
                  Reférence {{ espece|libelle }} [{{ espece.code }}]
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(p, i) in filtredParametersByFiche"
                :key="i"
              >
                <td>{{ p.libelle }}</td>
                <td class="valeur">
                  <span>{{ valeur(p) }}</span> <small
                    style="color: #b50404;"
                    v-html="NormalValeur(p)"
                  />
                </td>
                <td>
                  {{ valeurRef(p, espece) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="text-align: center; padding-bottom: 50px; min-height: 50px;">
                  Commentaire: 
                  <p class="text-left">
                    {{ commentaire }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </base-component>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseComponent from './base.vue'
import { ANALYSE_REFERENCE_VALEUR } from '../../../../constants/app'
export default {
    components: {BaseComponent},
    props: {
      analyseUid: {type: String, required: true},
      animal: {type: String, required: true},
      ficheUid: {type: String, required: true},
    },
    data(){
        return {
          
        }
    },
    methods: {
        valeur(parametre){
          let v = this.valeurs.find(item => item.parametre === parametre.uid)
          if(v) return v.valeur
          return '-'
        },
        NormalValeur(parametre){
          let ref = this.valeurRef(parametre, this.espece).split('-')
          let v = this.valeur(parametre) 
          if(v !== '-'){
            if(parseFloat(v) < parseFloat(ref[0])) return '<i class="las la-arrow-down"></i>'
            if(parseFloat(v) > parseFloat(ref[1])) return '<i class="las la-arrow-up"></i>'
            return null
          }
          return null
          
        },
        valeurRef(parametre, espece){
            let r = [...this.references].find(item => item.espece === espece.uid && item.parametre === parametre.uid)
            if(r) return r.valeur
            return '-'
        },
        filtredParametres(section){
            if(this.selectedObject !== null )
                return [...this.parametres].filter(item => item.fiche.split(',').includes(this.ficheUid) && item.section === section.uid)
            return []
        }
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            especes: 'identification/activeEspeces',
            fiches: 'sanitaire/ficheAnalyses',
            sections : 'sanitaire/ficheSections',
            parametres: 'sanitaire/ficheParametres',
            references: 'sanitaire/ficheReferences',
            resultats: 'analyse/resultatAnalyses',
            animaux: 'identification/animaux',
            typeFiches: 'sanitaire/typeFicheAnalyses',
        }),
        analyse(){
          return this.analyses.some(item => item.uid === this.analyseUid) ? this.analyses.find(item => item.uid === this.analyseUid) : null
        },
        hasAnalyse(){
          return this.analyse !== null
        },
        fiche(){
          return this.fiches.some(item => item.uid === this.ficheUid) ? this.fiches.find(item => item.uid === this.ficheUid) : null
        },
        resultat(){
          return this.resultats.some(item => item.analyse === this.analyseUid) ? this.resultats.find(item => item.analyse === this.analyseUid) : null
        },
        valeurs(){
          return (this.resultat !== null) ? JSON.parse(this.resultat.data) : []
        },
        commentaire(){
          return (this.resultat !== null) ? this.resultat.commentaire : '-'
        },
        date(){
          return (this.resultat !== null) ? this.resultat.createdAt : null
        },
        filtredSections(){
            if(this.fiche !== null )
                return [...this.sections].filter( item => item.fiche === this.ficheUid)
            return []
        },
        filtredParametersByFiche(){
          if(this.selectedObject !== null ){
            return [...this.parametres].filter(item => item.fiche.split(',').includes(this.ficheUid))
          }
          return []
        },
        isSectionTab(){
          return this.filtredSections.length > 0
        },
        animalObject(){
          return (this.animaux.some(item => item.uid === this.animal))? this.animaux.find(item => item.uid === this.animal) : null
        },
        espece(){
          if(this.animalObject === null) return null
          return (this.especes.some(item => item.uid === this.animalObject.espece))? this.especes.find(item => item.uid === this.animalObject.espece) : null
        },
        visible(){
          if(this.fiche !== null){
            let t = this.typeFiches.find(item => item.uid === this.fiche.categorieAnalyse)
            if(t) return t.code === ANALYSE_REFERENCE_VALEUR
          }
          return false
        }
    }

}
</script>

<style lang="scss" scoped>
.valeur{
  position: relative;
}
.valeur small{
  position: absolute;
  right: 5px;
}
</style>