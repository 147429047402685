<template>
  <div class="row">
    <div class="col-12">
      <div
        class="form-group"
        v-if="isReferenceValeurFiche"
      >
        <label :for="'parameter-'+index">{{ parametre.libelle }} <span class="badge badge-info">{{ reference }}</span></label>
        <input
          class="form-control"
          :name="'parameter-'+index"
          :id="'parameter-'+index"
          v-model="valeur"
        >
      </div>
      <div
        class="form-group"
        v-if="isResultatDirectFiche"
      >
        <label :for="'parameter-'+index">{{ parametre.libelle }} </label>
        <textarea
          rows="10"
          class="form-control"
          :name="'parameter-'+index"
          :id="'parameter-'+index"
          v-model="valeur"
        />
      </div> 
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ANALYSE_REFERENCE_VALEUR, ANALYSE_RESULTAT_DIRECT } from '../../../constants/app';
export default {
    props: {
        parametre: {type: Object, required: true},
        typeFiche: {type: Object, required: true},
        animal: {type: String, required: true},
        index: {type: Number, required: true}
    },
    data(){
        return {
            valeur: null
        }
    },
    mounted(){
        this.updateValeur()
    },
    watch: {
        monit: {
            handler(){
                this.addResultat(this.monit)
            },
            deep: true
        },
        reset(){
            if(this.reset) this.valeur = null
        },
        resultats: {
            handler(){
                this.updateValeur()
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            addResultat: 'analyse/ADD_RESULTAT',
        }),
        updateValeur(){
            if(this.resultats.some(item => item.parametre === this.parametre.uid)){
                let r = this.resultats.find(item => item.parametre === this.parametre.uid)
                if(this.valeur !== r.valeur) this.valeur = r.valeur
            }
        }
    },
    computed: {
        ...mapGetters({
            reset: 'analyse/reset',
            references: 'sanitaire/ficheReferences',
            animaux: 'identification/animaux',
            espece: 'identification/especes',
            resultats: 'analyse/resultats',
        }),
        isReferenceValeurFiche(){
            if(this.typeFiche !== null) return this.typeFiche.code === ANALYSE_REFERENCE_VALEUR
            return false
        },
        isResultatDirectFiche(){
            if(this.typeFiche !== null) return this.typeFiche.code === ANALYSE_RESULTAT_DIRECT
            return false
        },
        monit(){
            return {order: this.index, valeur: this.valeur, parametre: this.parametre.uid}
        },
        animalObject(){
            return this.animaux.find(item => item.uid === this.animal)
        },
        reference(){
            if(this.animalObject !== null && this.animalObject !== undefined){
                let r = this.references.find(item => item.parametre === this.parametre.uid && item.espece === this.animalObject.espece)
                if(r) return r.valeur
            }
            return '-'
        }
    }

}
</script>

<style>

</style>